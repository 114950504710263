@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Hiragino";
  src: local("Hiragino"), url(../assets/font/HiraginoKaku.otf) format("opentype");
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  overflow-x: auto;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 10px;
}

.lang:hover{
  cursor: pointer;
  scale: 1.05;
  font-weight: bold;  
}

.frame {
  align-items: center;
  background: linear-gradient(
    180deg,
    rgb(251, 217, 111) 0%,
    rgb(255, 193, 2) 100%
  );
  border-radius: 8px;
  box-shadow: 3px 4px 0px #908263;
  display: flex;
  gap: 8px;
  justify-content: center;
  overflow: hidden;
  padding: 12px 16px;
  position: relative;
  width: 100%;
  margin-top: 20px;
}
.frame .text-wrapper {
  color: #404040;
  flex: 1;
  font-family: "Hiragino";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19.5px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.frame:hover{
  /* scale: 1.05; */
  cursor: pointer;
}

.frame .text-wrapper:hover {
  scale: 1.05;
}